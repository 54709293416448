import { graphql, useStaticQuery } from "gatsby"

const Query = () => {
  return useStaticQuery(graphql`
      query GatsbyConfigQueryAndFooterQuery {
          site {
              siteMetadata {
                  title
                  description
                  keywords
                  author
                  support
                  android
                  company
                  faq
                  form
                  itPrize
                  login
                  patent
                  service
                  tireKarte
                  warehouse
                  webSystem
              }
          }
          allFooterJson {
              edges {
                  node {
                      li
                      a
                  }
              }
          }
      }
  `)
}

export default Query
