import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Images from "./image"
import Query from "./query"
import Val from "./0-val"
import { Grid1fr1fr, SpacerWithBaseMargin } from "./0-styled"

const HeaderS = styled.header`
  border-bottom: 9px solid ${Val.colorBg};
`
const Grid = styled.h1`
  margin-top: ${Val.baseMargin}px;
  padding: 0 ${Val.baseMargin}px ${Val.baseMargin}px ${Val.baseMargin}px;

  ${Grid1fr1fr};

  @media screen and (max-width: ${Val.baseWidthBk2nd}px) {
    gap: 0;
    grid-template-columns: 1fr;
  }
`
const HeaderLogo = styled(Link)`
  width: ${Val.logo}px;

  @media screen and (max-width: ${Val.baseWidthBk2nd}px) {
    ${SpacerWithBaseMargin};
  }
`
const HeaderInquiry = styled(Link)`
  width: ${Val.inquiry}px;
  margin: 0 0 0 auto;

  @media screen and (max-width: ${Val.baseWidthBk3rd}px) {
    width: 100%;
  }
`

const Header = () => {
  const data = Query()

  return (
    <HeaderS>
      <Grid>
        <HeaderLogo to={"/"}>
          <Images
            filename={"header/logo.jpg"}
            alt={data.site.siteMetadata.title}
          />
        </HeaderLogo>

        <HeaderInquiry to={"/inquiry/"}>
          <Images
            filename={"header/inquiry.jpg"}
            alt={data.site.siteMetadata.form}
          />
        </HeaderInquiry>
      </Grid>
    </HeaderS>
  )
}

export default Header
