import { css } from "styled-components"
import Val from "./0-val"

export const Border9pxSolidBlack = css`
  border-bottom: 9px solid ${Val.colorBg};
`

export const CenteringInBaseWidth = css`
  margin: 0 auto;
  max-width: ${Val.baseWidth}px;
  padding: 0 ${Val.baseMargin}px;
`

export const flexStartWrap = css`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`

export const Grid3fr1fr = css`
  display: grid;
  gap: ${Val.baseMargin}px;
  grid-template-columns: 3fr 1fr;
`

export const Grid1fr1fr = css`
  display: grid;
  gap: ${Val.baseMargin}px;
  grid-template-columns: 1fr 1fr;
`

export const Hero = css`
  ${Border9pxSolidBlack};
  margin-bottom: ${Val.baseMargin}px;
`

export const SpacerWithBaseMargin = css`
  margin-bottom: ${Val.baseMargin}px;
`
