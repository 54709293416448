import React from "react"
import styled from "styled-components"
import Images from "../image"
import Query from "../query"
import Val from "../0-val"
import { CenteringInBaseWidth, Hero } from "../0-styled"

const HeroIndexS = styled.section`
  ${Hero};
`
const HeroIndexImg = styled.div`
  ${CenteringInBaseWidth};
  
  margin-top: ${Val.baseMargin}px;
  width: ${Val.HeroIndexImgWidth}px;

  @media screen and (max-width: ${Val.HeroIndexImgWidth}px) {
    width: 100%;
  }
`

const HeroIndex = () => {
  const data = Query()

  return (
    <HeroIndexS>

      <HeroIndexImg>
        <Images filename={"top/hero/fg.jpg"} alt={data.site.siteMetadata.description}/>
      </HeroIndexImg>

      <HeroIndexImg>
        <Images filename={"top/hero/bg.jpg"} alt={data.site.siteMetadata.description}/>
      </HeroIndexImg>

    </HeroIndexS>
  )
}
export default HeroIndex
