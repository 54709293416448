import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 *
 * - [パスを渡せばgatsby\-imageで画像を表示してくれるコンポーネントの作成方法 \| Takumon Blog]
 *    (https://takumon.com/simple-gatsby-image-wrapper)
 */

/**
 * @property {object} childImageSharp
 */

const Images = ({ filename, alt }) => {
  const data = useStaticQuery(graphql`
      query {
          images: allFile {
              edges {
                  node {
                      relativePath
                      name
                      childImageSharp {
                          fluid(quality: 100) {
                              ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                      }
                  }
              }
          }
      }
  `)

  const image = data.images.edges.find(n => {
    return n.node.relativePath.includes(filename)
  })

  if (!image) return null

  return <Img loading={"eager"} fluid={image.node.childImageSharp.fluid} alt={alt} fadeIN={false} durationFadeIn={0}/>
}

Images.propTypes = { filename: PropTypes.string, alt: PropTypes.string }
Images.defaultProps = { filename: ``, alt: `` }

export default Images
