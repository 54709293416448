import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Query from "./query"
import Val from "./0-val"
import { CenteringInBaseWidth, flexStartWrap, SpacerWithBaseMargin } from "./0-styled"

/**
 * @property {object} allFooterJson
 */

const FooterS = styled.footer`
  background-color: ${Val.colorBg};
  padding: ${Val.baseMargin}px 0;
  
  & * {
    color: ${Val.colorWhite};
  }
`
const FooterLinkList = styled.ul`
  ${CenteringInBaseWidth};
  ${flexStartWrap};

  justify-content: center;

  & li {
    ${SpacerWithBaseMargin};

    margin-right: ${Val.baseMargin}px;

    & a {
      text-decoration: underline;
    }
  }
`
const CopyRight = styled.p`
  ${CenteringInBaseWidth};

  text-align: center;
`

const Footer = () => {
  const data = Query()

  let items = []

  data.allFooterJson.edges.forEach(i =>
    items.push(
      <li key={i.node.a}>
        ●&nbsp;<Link to={i.node.a}>{i.node.li}</Link>
      </li>,
    ),
  )

  return (
    <FooterS>
      <FooterLinkList>{items}</FooterLinkList>

      <CopyRight>
        &copy;&nbsp;{data.site.siteMetadata.company}
      </CopyRight>
    </FooterS>
  )
}

export default Footer
