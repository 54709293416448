import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Images from "./image"
import Query from "./query"
import Prime from "./0-prime"
import { SpacerWithBaseMargin } from "./0-styled"

const TireKarte = styled.a`
  ${SpacerWithBaseMargin};

  display: block;
`
const Inquiry = styled(Link)`
  display: block;
  margin-bottom: ${Prime.sp61}px;
`

const BottomBar = () => {
  const data = Query()

  return (
    <>
      <TireKarte href="https://tirekarte.jp/">
        <Images
          filename={"top/tire-karte.jpg"}
          alt={data.site.siteMetadata.tireKarte}
        />
      </TireKarte>

      <Inquiry to={"/inquiry/"}>
        <Images
          filename={"top/inquiry.jpg"}
          alt={data.site.siteMetadata.form}
        />
      </Inquiry>
    </>
  )
}

export default BottomBar
