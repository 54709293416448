/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

/*
* The Import order
*   1. react
*   2. prop-types
*   3. gatsby
*   4. plugins
*   5. components
*   6. pages
*   7. css or scss
 */

/**
 * @property {object} site
 */

import React from "react"
import PropTypes from "prop-types"
import "ress"
import "../scss/0-base.scss"
import styled from "styled-components"
import BottomBar from "./4-bottomBar"
import Breadcrumb from "./3-breadcrumb"
import Footer from "./6-footer"
import Header from "./1-header"
import HeroIndex from "./hero/2-heroIndex"
import HeroService from "./hero/2-heroService"
import HeroWarehouse from "./hero/2-heroWarehouse"
import Nav from "./5-nav"
import Val from "./0-val"
import { CenteringInBaseWidth, Grid3fr1fr } from "./0-styled"

const Main = styled.main`
  ${CenteringInBaseWidth};
  ${Grid3fr1fr};
  
  @media screen and (max-width: ${Val.baseWidthBk1st}px) {
    gap: 0;
    grid-template-columns: 1fr;
  }
`
const Layout = ({ children, sitePath, pageTitle }) => {
  // const data = Query()

  let hero

  switch (sitePath) {
    case "warehouse":
      hero = <HeroWarehouse id={sitePath}/>
      break

    case "service":
      hero = <HeroService id={sitePath}/>
      break

    case "index":
      hero = <HeroIndex/>
      break

    default:
      hero = <HeroIndex/>
      break
  }

  const isIE = () => {
    if (typeof window !== `undefined`) {
      let userAgent = window.navigator.userAgent.toLowerCase()

      if ((userAgent.indexOf("msie") !== -1 || userAgent.indexOf("trident") !== -1) && (sitePath !== `inquiry`)) {
        window.location.href = "/old/"
      }
    }

    return children
  }

  isIE()

  return (
    <>
      <Header/>

      {hero}

      <Main id={sitePath}>

        <div>
          {Breadcrumb(pageTitle)}
          {children}
          {BottomBar()}
        </div>

        <Nav/>

      </Main>

      <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  sitePath: PropTypes.string,
  pageTitle: PropTypes.string,
}
Layout.defaultProps = {
  sitePath: ``,
  pageTitle: ``,
}

export default Layout
