import Prime from "./0-prime"

export default class Val {
  // img --------------------
  static inquiry = 331;
  static logo = 230;

  // base --------------------
  static baseMargin = Prime.sp11;
  static baseWidthBk1st = 767;
  static baseWidthBk2nd = this.logo + this.inquiry + this.baseMargin * 3;
  static baseWidthBk3rd = this.inquiry + this.baseMargin * 2;
  static baseWidthNavImg = 300;
  static baseWidth = 960;
  static HeroIndexImgWidth = 780;

// colors --------------------
  static colorBg = `#141414`;
  static colorBlue = `#00A5Be`;
  static colorGray = `#aac7d1`;
  static colorGreen = `#A5DC0F`;
  static colorOrange = `#EA5404`;
  static colorWhite = `#FEFEFE`;
  static colorRed = `#d1005d`;
  static colorBb = `#058096`;

  // img --------------------
  static imgWidthLogoNew = Prime.sp421;
  static imgWidthLogoOld = Prime.sp349;
  static imgWidthIndexHeroItems = Prime.sp167;

  // width --------------------
  static widthBase = "768px";
  static widthBpZero = "693px";
  static widthBp1st = "564px";
  static widthBp2nd = "514px";
  static widthIndexHero = "1096px";

  // base --------------------
  static marginBase = Prime.sp31;
  static marginSeparate = Prime.sp199;
  static marginSeparateHalf = Prime.sp101;

  // font-size --------------------
  static fontSizeBase = Prime.sp13;
  static fontSizeTitle = Prime.sp19;
  static fontSizeBigTitle = Prime.sp61;
}
