import styled from "styled-components"
import Val from "../0-val"
import { CenteringInBaseWidth, Grid3fr1fr, Hero, SpacerWithBaseMargin } from "../0-styled"

export const HeroOthers = styled.section`
  ${Hero};

  background-color: ${Val.colorBg};

  & #hero-wrapper {
    ${CenteringInBaseWidth};
    ${Grid3fr1fr};

    @media screen and (max-width: ${Val.baseWidthBk1st}px) {
      gap: 0;
      grid-template-columns: 1fr;
    }
  }

  & #hero-bg-0 {
    ${SpacerWithBaseMargin};

    @media screen and (max-width: ${Val.baseWidthBk1st}px) {
      margin-bottom: 0;
    }
  }

  & #hero-fg {
    @media screen and (max-width: ${Val.baseWidthBk1st}px) {
      ${SpacerWithBaseMargin};
    }
  }

  & #hero-bg {
    @media screen and (max-width: ${Val.baseWidthBk1st}px) {
      display: grid;
      gap: ${Val.baseMargin}px;
      grid-template-columns: 1fr 1fr;
    }
  }
`
