export default class Prime {
  static sp03 = 3;
  static sp05 = 5;
  static sp07 = 7;
  static sp09 = 9;
  static sp11 = 11;
  static sp13 = 13;
  static sp17 = 17;
  static sp19 = 19;
  static sp23 = 23;
  static sp29 = 29;
  static sp31 = 31;
  static sp37 = 37;
  static sp41 = 41;
  static sp43 = 43;
  static sp47 = 47;
  static sp53 = 53;
  static sp59 = 59;
  static sp61 = 61;
  static sp67 = 67;
  static sp71 = 71;
  static sp73 = 73;
  static sp79 = 79;
  static sp83 = 83;
  static sp89 = 89;
  static sp97 = 97;
  static sp101 = 101;
  static sp103 = 103;
  static sp107 = 107;
  static sp109 = 109;
  static sp113 = 113;
  static sp127 = 127;
  static sp131 = 131;
  static sp137 = 137;
  static sp139 = 139;
  static sp149 = 149;
  static sp151 = 151;
  static sp157 = 157;
  static sp163 = 163;
  static sp167 = 167;
  static sp173 = 173;
  static sp179 = 179;
  static sp181 = 181;
  static sp191 = 191;
  static sp193 = 193;
  static sp197 = 197;
  static sp199 = 199;
  static sp211 = 211;
  static sp223 = 223;
  static sp227 = 227;
  static sp229 = 229;
  static sp233 = 233;
  static sp239 = 239;
  static sp241 = 241;
  static sp251 = 251;
  static sp257 = 257;
  static sp263 = 263;
  static sp269 = 269;
  static sp271 = 271;
  static sp277 = 277;
  static sp281 = 281;
  static sp283 = 283;
  static sp293 = 293;
  static sp307 = 307;
  static sp311 = 311;
  static sp313 = 313;
  static sp317 = 317;
  static sp331 = 331;
  static sp337 = 337;
  static sp347 = 347;
  static sp349 = 349;
  static sp353 = 353;
  static sp359 = 359;
  static sp367 = 367;
  static sp373 = 373;
  static sp379 = 379;
  static sp383 = 383;
  static sp389 = 389;
  static sp397 = 397;
  static sp401 = 401;
  static sp409 = 409;
  static sp419 = 419;
  static sp421 = 421;
  static sp431 = 431;
  static sp433 = 433;
  static sp439 = 439;
  static sp443 = 443;
  static sp449 = 449;
  static sp457 = 457;
  static sp461 = 461;
  static sp463 = 463;
  static sp467 = 467;
  static sp479 = 479;
  static sp487 = 487;
  static sp491 = 491;
  static sp499 = 499;
  static sp503 = 503;
  static sp509 = 509;
  static sp521 = 521;
  static sp523 = 523;
  static sp719 = 719;
  static sp953 = 953;
}