import React from "react"
import styled, { css } from "styled-components"
import Images from "./image"
import Query from "./query"
import Prime from "./0-prime"
import Val from "./0-val"
import { SpacerWithBaseMargin } from "./0-styled"

const NavS = styled.nav`
  margin: 0 auto;
`
const Banner = styled.p`
  ${SpacerWithBaseMargin};
`
const BannerLink = Banner.withComponent(`a`)

const Title = css`
  ${SpacerWithBaseMargin};

  background-color: ${Val.colorBg};
  color: ${Val.colorWhite};
  font-weight: bold;
  letter-spacing: 0.3rem;
  padding: 5px 0 3px ${Val.baseMargin}px;
`
const TitleBlue = styled.h1`
  ${Title};
  border-left: ${Prime.sp11}px solid ${Val.colorBlue};
`
const TitleLoginCommon = css`
  border-radius: ${Prime.sp05}px;
  display: block;
  padding: ${Val.baseMargin * 2}px;
  text-align: center;
  width: 100%;
  height: 100px;
`
const TitleLogin = styled.a`
  ${Title};
  ${TitleLoginCommon};
`
// const TitleLoginNew = styled(TitleLogin.withComponent(`a`))`
//   ${Title};
//   ${TitleLoginCommon};
//   background-color: ${Val.colorBlue};
//   margin-bottom: ${Prime.sp61}px;
// `

const News = styled.ul`
  margin-bottom: ${Val.baseMargin * 3}px;
  margin-left: ${Val.baseMargin * 2}px;

  & li {
    list-style-type: disc;
  }
`

const Nav = () => {
  const data = Query()

  return (
    <NavS>
      <TitleBlue>LOGIN</TitleBlue>

      <Banner>{data.site.siteMetadata.login}</Banner>

      <TitleLogin href={"https://v4.system.tirepark.seasonbox.jp/"}>
        ログイン
      </TitleLogin>

      {/*<TitleLoginNew href={"https://v4.system.tirepark.seasonbox.jp/"}>*/}
      {/*  ログイン*/}
      {/*  <br />*/}
      {/*  <span>新バージョン</span>*/}
      {/*  <br />*/}
      {/*  <span style={{ color: `red` }}>試験運用中</span>*/}
      {/*</TitleLoginNew>*/}

      <TitleBlue>システム更新情報</TitleBlue>

      <News>
        <li>
          2020/2/22(金) <br />
          一部の倉庫業者さま向けに「タイヤパーク新バージョン」をリリースしました。
        </li>

        <li>
          2020/5/25(月) <br />
          試験運用中の「新バージョン」についてのご案内 （詳細はリンク先の
          <a
            href="https://drive.google.com/file/d/1Vw7KGEDv1UzbXroyvDYDG_3eFAQpcwQU/view?usp=sharing"
            target={`_blank`}
          >
            <u style={{ color: `blue` }}>PDF</u>
          </a>{" "}
          をご参照ください）
        </li>
      </News>

      <BannerLink href={"http://reitouko.jp/"}>
        <Images
          filename={"nav/company.jpg"}
          alt={data.site.siteMetadata.company}
        />
      </BannerLink>

      <BannerLink>
        <Images
          filename={"nav/prize-2014.jpg"}
          alt={data.site.siteMetadata.itPrize}
        />
      </BannerLink>

      <BannerLink href="https://privacymark.jp/">
        <Images
          filename={"nav/10440068_200_JP.png"}
          alt={data.site.siteMetadata.itPrize}
        />
      </BannerLink>
    </NavS>
  )
}

export default Nav
