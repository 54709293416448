import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import Val from "./0-val"

const BreadcrumbS = styled.div`
  display: inline-block;
  margin-bottom: ${Val.baseMargin * 2}px;

  & a {
    color: ${Val.colorBlue};
    text-decoration: underline;
  }

  & span {
    margin-left: ${Val.baseMargin}px;
  }
`
const Breadcrumb = (v) => {
  if (v !== "") {
    return (
      <BreadcrumbS>

        <Link to={"/"}>ホーム</Link>

        <span>&raquo;</span>

        <span>{v}</span>

      </BreadcrumbS>
    )
  }
}

Breadcrumb.propTypes = { v: PropTypes.string }
Breadcrumb.defaultProps = { v: `` }

export default Breadcrumb
