import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Images from "../image"
import Query from "../query"
import { HeroOthers } from "./0-heroStyled"

const HeroService = ({ id }) => {
  const data = Query()

  return (
    <HeroOthers>
      <div id={"hero-wrapper"}>

        <div id={"hero-fg"}>
          <Images filename={id + "/hero/fg.jpg"}
                  alt={data.site.siteMetadata.service}/>
        </div>

        <div id={"hero-bg"}>
          <div id={"hero-bg-0"}>
            <Link to={"/warehouse/"}>
              <Images filename={"warehouse/hero/warehouse-off.jpg"}
                      alt={data.site.siteMetadata.warehouse}/>
            </Link>
          </div>

          <div>
            <Images filename={"warehouse/hero/service-on.jpg"}
                    alt={data.site.siteMetadata.service}/>
          </div>
        </div>

      </div>
    </HeroOthers>
  )
}

HeroService.propTypes = { id: PropTypes.string }
HeroService.defaultProps = { id: `` }

export default HeroService
